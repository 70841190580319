.add-reviece-success-button {
  width: 279px;
  background: #0b436d;
  border-radius: 16px;
  height: 56px;
  outline: none;
  color: white;

  font-family: 'Inter', sans-serif;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.03em;
  outline: none;
  border: none;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.add-reviece-success-text {
  font-family: 'Inter', sans-serif;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.03em;

  color: #333333;
}

.add-reviece-success-amount {

  font-family: 'Inter', sans-serif;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.03em;
  color: #0b436d;
}

.tag-container {
  display: flex;
  background: #FFFFFF;
  justify-content: space-between;
  width: 80%;
  /* border-bottom: 2px dashed #E3E5E6; */
  align-items: center;
}

.active-tag {
  border: 1px solid #C6CACC;
  border-radius: 4px 4px 0px 0px;
  border-bottom: 1px solid #FFFFFF;
  width: 70%;
}

.active-tag>p {
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.5px;
  color: #000000;

}

.unactive-tag {
  width: 70%;

}

.unactive-tag>p {
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.5px;
  color: #5E6366;
}

button:disabled {
  opacity: 0.8;
  cursor: not-allowed;
}