.loader_wrapper {
	position: absolute;
	top: 50%;
	left: 50%;
	-ms-transform: translateX(-50%) translateY(-50%);
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	align-items: center;
}

.loader_bg {
	background: #0a426d !important;
}