.sidebar-div {
  flex: 0.2
}

.children-div {

  border-left: 2px solid rgba(196, 196, 196, 0.5);
  margin-left: 1%;

}

.sidebar-position-div {
  position: fixed;
  height: 100%;
}

.right-panel {
  position: absolute;
  right: 0;
}

.right-top-panel {
  z-index: 10;
  height: 107px;
  position: fixed;
  right: 0;
  width: 75%;
  background: #FFF;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04);
}

@media screen and (max-width :768px) {
  .right-top-panel {
    width: 100%;
  }
}

.right-bottom-panel {
  margin-top: 110px;
  flex-flow: row wrap;
}

@media screen and (max-width :1075px) {
  .sidebar-div {
    flex: 0.3
  }

  .children-div {
    background: #FCFCFC;
    border-left: 2px solid rgba(196, 196, 196, 0.5);
    margin-left: 1%;
  }
}