.sidebar-container {
    height: 100%;
    background: #0B436D;
}

.user-name-first {
    color: rgba(235, 232, 232, 0.61);
    font-size: 16px;
    font-weight: 400;
    line-height: 2px;
    letter-spacing: -0.32px;
}

.user-name-second {
    color: #FFF;
    font-size: 16px;
    font-weight: 400;
    line-height: 2px;
    letter-spacing: -0.56px;
    white-space: nowrap;
}

.siderbar-top {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 5%;
    width: 85%;

}

.sidebar-top-text>p:first-child {
    margin: 0px;
    margin-top: 15px;
    font-size: 16px;
    color: #0B436D;
    font-family: 'Rubik', sans-serif;
    cursor: pointer;

}

.sidebar-top-text>p:nth-child(2) {
    margin-top: 5%;
    color: #333333;
    font-size: 13px;
    font-family: 'Rubik', sans-serif;

}

.siderbar-balance {
    display: flex;
    margin-top: 10%;
    align-items: center;
    flex-direction: column;
}

.sidebar-balance-heading {
    width: 65%;
    margin: 0px;
    color: #33333380;
    font-size: 17px;
    font-family: 'Rubik', sans-serif;

}

.sidebar-balance-text-container {
    display: flex;
}

.sidebar-balance-text-container>p:first-child {
    margin-right: 25px;
}

.sidebar-balance-text-container>p:first-child {
    color: #27AE60;
    font-size: 20px;
    font-family: 'Rubik', sans-serif;

}

.sidebar-balance-text-container>p:nth-child(2) {
    color: #27AE60;
    font-size: 15px;
    background-color: #EEFBF4;
    border-radius: 8px;
    padding: 5px 10px 5px 10px;
    font-family: 'Rubik', sans-serif;

}

.sidebar-link-main-container {
    margin-top: 20px;
}

.sidebar-links-container {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 3px;
}

.sidebar-links {
    display: flex;
    width: 90%;
}

.sidebar-links-text-p {
    color: #FFF;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    white-space: nowrap;
}

.sidebar-sub-links-text-p {
    color: #FFF;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    white-space: nowrap;
}

.sidebar-links-text-p-inactive {
    color: rgba(233, 233, 234, 0.74);
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    white-space: nowrap;
}


.sidebar-logout-container {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-grow: 1;
    margin-top: 30px;
}


@media screen and (max-width:830px) {}

@media screen and (max-width:576px) {
    .siderbar-top {
        margin-left: 15%;
    }

    .sidebar-links {
        display: flex;
        width: 95%;
    }

}

.sidebar_active_icon {
    fill: #fff;
}


.active-link-btn {
    border: 2px solid #8EA0BA;
    height: 11px;
    background: white;
    border-radius: 11px;
    width: 11px;
}

.new-box-enter {
    animation: growDown 350ms ease-in-out forwards;
    transform-origin: top center
}

.new-box-exit {
    animation: growUp 350ms ease;
    transform-origin: top center;
}

@keyframes growDown {
    0% {
        transform: scaleY(0)
    }

    80% {
        transform: scaleY(1.1)
    }

    100% {
        transform: scaleY(1)
    }
}

@keyframes growUp {
    0% {
        transform: scaleY(1)
    }

    20% {
        transform: scaleY(1.2)
    }

    100% {
        transform: scaleY(0)
    }
}